<template>
  <div v-if="sb_plans" class="sb-plans-group" data-test-id="sb-plans-group">
    <div class="sb-plans-group-container">
      <div class="sb-plans-group-container-plans">
        <SbPlan v-for="plan in sb_plans" :key="plan._uid" :plan="plan" @select-plan="selectPlan" />
      </div>
    </div>
    <SbSubscribeModal v-if="openSubscriptionModal && selectedPlan" :plans="selectedPlans" :plan="selectedPlan" />
  </div>
</template>

<script>
import SbPlan from '@/components/Storyblok/SbPlan'
import SbSubscribeModal from '@/components/Storyblok/SbSubscribeModal'

export default {
  name: 'SbPlansGroup',
  components: {
    SbPlan,
    SbSubscribeModal,
  },
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    sb_plans: { type: Array, default: undefined },
    classVariant: { type: null, default: '' },
    // eslint-disable-next-line vue/prop-name-casing
    has_padding_vertical: { type: Boolean, default: false },
  },
  data() {
    return {
      openSubscriptionModal: false,
      selectedPlan: null,
      selectedPlans: [],
    }
  },
  watch: {
    $route: {
      handler(v) {
        if (v.query && v.query.subscribe == 1) {
          this.openSubscriptionModal = true
        } else if (v.query && v.query.subscribe == 0) {
          this.openSubscriptionModal = false
        }
      },
      immediate: true,
    },
  },
  methods: {
    selectPlan(plans, selectedPlan) {
      this.selectedPlan = selectedPlan
      this.selectedPlans = plans
    },
  },
}
</script>

<style lang="scss">
.sb-plans-group {
  padding: $spacing-xl;
  background-color: var(--white);

  @include mq($mq-xs) {
    padding: $spacing-md;
  }

  &-container {
    max-width: 1000px;
    margin: 0 auto;

    &-title {
      text-align: center;
      font-weight: 800;
      font-size: pxToRem(30px);
      padding-bottom: $spacing-xl;
    }

    &-subtitle {
      font-weight: 400;
      font-size: pxToRem(15px);
    }

    &-plans {
      display: flex;
      gap: $spacing-md;
      overflow-x: scroll;

      .sb-plan {
        border: 1px solid var(--light-grey);
      }
    }
  }
}
</style>
